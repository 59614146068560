import type { RouteRecordRaw } from "vue-router"
import { coerceParams } from "@/commons/utils/router"

export const OrganizationRoutesDefinitions = {
  ROOT: { name: "organization" },
  ORGANIZATION_DETAILS: { name: "organization/details" },
  USERS: { name: "organization/users" },
  ORDERS: { name: "organization/orders" },
  ORDERS_DETAILS: { name: "organization/orders-details" },
  RETIREMENTS: { name: "organization/retirements" },
  DUE_DILIGENCE_REPORTS: { name: "organization/due-diligence-reports" },
  DECARBONIZATION: { name: "organization/decarbonization" },
  EXTERNAL_PROJECTS: { name: "organization/external-projects" },
  EXTERNAL_PROJECT_DETAILS: { name: "organization/external-projects-details" },
} as const

export const organizationRoutes: RouteRecordRaw[] = [
  {
    path: "/organization",
    name: OrganizationRoutesDefinitions.ROOT.name,
    component: () => import("./views/ViewOrganization.vue"),
    redirect: OrganizationRoutesDefinitions.ORGANIZATION_DETAILS,
    children: [
      {
        path: "details",
        name: OrganizationRoutesDefinitions.ORGANIZATION_DETAILS.name,
        component: () => import("./views/ViewOrganizationDetails.vue"),
        meta: {
          requirePermissions: ["VIEW_ORGANIZATION_DETAILS"],
        },
      },
      {
        path: "users",
        name: OrganizationRoutesDefinitions.USERS.name,
        component: () => import("./views/ViewOrganizationUsers.vue"),
        meta: {
          requirePermissions: ["VIEW_ORGANIZATION_MEMBERS"],
        },
      },
      {
        path: "orders",
        children: [
          {
            path: "",
            name: OrganizationRoutesDefinitions.ORDERS.name,
            component: () => import("./views/ViewOrganizationOrders.vue"),
          },
          {
            path: ":id",
            name: OrganizationRoutesDefinitions.ORDERS_DETAILS.name,
            component: () => import("./views/ViewOrganizationOrdersDetails.vue"),
            props: true,
          },
        ],
        meta: {
          requirePermissions: ["VIEW_ORDERS"],
        },
      },
      {
        path: "retirements",
        name: OrganizationRoutesDefinitions.RETIREMENTS.name,
        component: () => import("./views/ViewOrganizationRetirements.vue"),
        meta: {
          requirePermissions: ["VIEW_PORTFOLIO"],
        },
      },
      {
        path: "due-diligence-reports",
        name: OrganizationRoutesDefinitions.DUE_DILIGENCE_REPORTS.name,
        component: () => import("./views/ViewOrganizationDdReports.vue"),
        meta: {
          requirePermissions: ["VIEW_REPORTS"],
        },
      },
      {
        path: "decarbonization",
        name: OrganizationRoutesDefinitions.DECARBONIZATION.name,
        component: () => import("./views/ViewOrganizationDecarbonization.vue"),
      },
      {
        path: "external-projects",
        name: OrganizationRoutesDefinitions.EXTERNAL_PROJECTS.name,
        component: () => import("./views/ViewOrganizationExternalProjects.vue"),
      },
      {
        path: "external-projects/:id",
        name: OrganizationRoutesDefinitions.EXTERNAL_PROJECT_DETAILS.name,
        component: () => import("./views/ViewOrganizationExternalProjectUpdate.vue"),
        props: coerceParams,
      },
    ],
  },
]
