import { hasFeatureFlag } from "@/commons/plugins/feature-flags"
import calliriusTheme from "@/commons/themes/callirius"
import lgtTheme from "@/commons/themes/lgt"
import lorealTheme from "@/commons/themes/loreal"
import volkswagenTheme from "@/commons/themes/volkswagen"

export const useTheme = () => {
  let theme = calliriusTheme
  if (hasFeatureFlag("enable_theme_vw")) {
    theme = volkswagenTheme
  } else if (hasFeatureFlag("enable_theme_loreal")) {
    theme = lorealTheme
  } else if (hasFeatureFlag("enable_theme_lgt")) {
    theme = lgtTheme
  }

  const injectStyles = () => {
    const root = document.documentElement
    for (const [key, value] of Object.entries(theme.cssVariables)) {
      root.style.setProperty(key, value)
    }

    theme.importCustomStyles?.()
  }

  const getLogo = () => theme.logo

  const getHeaderBackground = () => theme.headerBackground

  const getPrimevueTheme = () => theme.primevueTheme

  const getChartColors = () => theme.chartColors

  return {
    injectStyles,
    getLogo,
    getHeaderBackground,
    getPrimevueTheme,
    getChartColors,
  }
}
