import type { Theme } from "@/commons/typings/theme"
import headerBackground from "./img/header-background.jpg"
import logo from "./img/logo.svg"

const lgtTheme: Theme = {
  logo,
  headerBackground,
  chartColors: [
    "#283c83",
    "#172258",
    "#52e8cd",
    "#00fdff",
    "#37d1df",
    "#ecf9fe",
    "#738a94",
    "#f0fcfa",
    "#2a4756",
    "#b0d8e5",
    "#e8e8e8",
  ],
  importCustomStyles: () => import("./styles/index.css"),
  cssVariables: {
    "--color-primary": "#172258",
    "--color-secondary": "#52e8cd",
    "--color-tertiary": "#00fdff",
    "--color-dark": "#283c83",
    "--color-on-primary": "#fff",
    "--color-on-secondary": "#000",
    "--color-on-tertiary": "#000",
    "--color-on-dark": "#fff",
    "--font-family": "Frutiger, Arial, Helvetica, sans-serif",
    "--font-family-heading": "Cambon, Arial, Helvetica, sans-serif",
    "--font-family-mono": '"Chivo Mono", monospace',
    "--header-gradient":
      "linear-gradient(90deg, color-mix(in srgb, #000000 60%, transparent), color-mix(in srgb, black 40%, transparent))",
  },
  primevueTheme: {
    semantic: {
      // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
      primary: {
        50: "#e8ebf4",
        100: "#c4cce4",
        200: "#9eabd1",
        300: "#798abe",
        400: "#5c70b1",
        500: "#3f57a5",
        600: "#384f9b",
        700: "#2f458f",
        800: "#283c83",
        900: "#1c2b6c",
        950: "#0f1b4f",
      },
      secondary: {
        50: "#dafaf4",
        100: "#a2f1e0",
        200: "#52e8cd",
        300: "#00dbb7",
        400: "#00cea6",
        500: "#00c296",
        600: "#00b388",
        700: "#00a176",
        800: "#009067",
        900: "#007249",
        950: "#004d2b",
      },
      colorScheme: {
        light: {
          primary: {
            color: "{primary.800}",
          },
        },
      },
    },
  },
}

export default lgtTheme
